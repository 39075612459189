import React, { useState, useEffect } from "react";
// import PlagiaCard from "../PlagiaCard";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { trackTextAreaFilled, trackCheckPlagiarismButtonClicked } from "../../utils/tracking.ts"

// var createHost = require("cross-domain-storage/host");
// var createGuest = require("cross-domain-storage/guest");

// var storageHost = createHost([
//   {
//     origin: "http://localhost:8000",
//     allowedMethods: ["get", "set", "remove"],
//   },
//   {
//     origin: "https://plagiashield.com",
//     allowedMethods: ["get", "set", "remove"],
//   },
//   {
//     origin: "https://app.plagiashield.com",
//     allowedMethods: ["get", "set", "remove"],
//   },
//   {
//     origin: "https://dev.plagiashield.com",
//     allowedMethods: ["get", "set", "remove"],
//   },
//   {
//     origin: "https://devapp.plagiashield.com",
//     allowedMethods: ["get", "set", "remove"],
//   },
// ]);

// var storageGuest = createGuest();

const TextForm = () => {
  const [sentTracking, setSentTracking] = useState(false);

  const handleChange = (event) => {
    event.persist();
    if (!sentTracking) {
      if (event.target.value) {
        if (event.target.value.length > 5) {
          trackTextAreaFilled();
          setSentTracking(true);
        }
      }
    }
    // sessionStorage.setItem("plagiarism-text", event.target.value);
    // storageGuest.set("plagiarism-text", event.target.value, function (error, data) {
    //   console.debug(error);
    //   console.debug(data);
    // });
  };

  const onSubmit = () => {
    // event.preventDefault()
    // e.stopPropagation()
    console.debug('submit clicked')
    trackCheckPlagiarismButtonClicked()
    console.log(window)
    setTimeout(() => {
      if (window !== undefined) {
        console.debug("location change")
        window.location = "https://app.plagiashield.com/app/signup"
      }
    }, 200);
  }

  // useEffect(() => {
  //   // set rules for accessing the data

  // }, []);

  return (
    <div style={{ textAlign: "center" }}>

      <Form style={{ textAlign: "center" }}
      // onSubmit={onSubmit}
      >
        <Form.Group controlId="text">
          {/* <Form.Label>Example textarea</Form.Label> */}
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter your text, up to 2000 words."
            required
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
      <Button
        className="mt-2"
        size="lg"
        onClick={() => {
          onSubmit();
        }}

      >
        Check Plagiarism
      </Button>
    </div>
  );
};

export default TextForm;

const useForm = (callback) => {
  const [values, setValues] = useState({});

  return {
    handleChange,
    handleSubmit,
    values,
  };
};
