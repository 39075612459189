import React from "react";
import Seo from "../components/Seo";
import PlagiarismForm from "../components/PlagiarismForm";
import Container from "react-bootstrap/Container";

const title = "Plagiarism Checker - PlagiaShield";
const description =
  "Plagiarism checker. Find out if your content is unique. Get rid of content thefts.";

const IndexPage = () => {
  return (
    <React.Fragment>
      <div className="plagia-checker-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Plagiarism Checker</h1>
      </div>
      <Container className="pb-4">
        <PlagiarismForm />
      </Container>

      <Seo manualTitle={title} manualDescription={description} manualSlug="/plagiarism-checker" />
    </React.Fragment>
  );
};

export default IndexPage;
